
import React from "react";
import Badge from 'react-bootstrap/Badge'
import MyGAd3 from '../components/MyGad3';

function Terms(props) {
    return (
  <div className="col-xl-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xl-5">
      </div>
      <div className="col-lg-7 col-xl-7">
          <h3> عن  افنان </h3>
          <div className="textarab" dir="rtl">
         <br/>
         <p><a href="/">Evnan.com</a> عبارة عن بوابة إنترنت, و <a href="https://www.bing.com/search?q=%D9%85%D8%AD%D8%B1%D9%83+%D8%A8%D8%AD%D8%AB+%D8%A5%D8%AE%D8%A8%D8%A7%D8%B1%D9%8A&cvid=68f029e7766548f0a9c88a69655361dd&aqs=edge..69i57j69i59i450l7&FORM=ANCMS9&PC=EDGEDB">محرك بحث إخباري</a> عربي، ومجموعة خدمات وتطبيقات ذات صلة. </p>
         <p> أفنان نيوز, موقع ينشر أ<a href="https://www.google.com/search?q=%D8%A3%D9%87%D9%85+%D8%A7%D9%84%D8%A3%D8%AE%D8%A8%D8%A7%D8%B1&sxsrf=ALiCzsY1yJb71umgHjn8LV3JAw1L3cKbYg%3A1651139335786&source=hp&ei=B2NqYsX4LZeChbIPz8yz8Aw&iflsig=AJiK0e8AAAAAYmpxF5Ss2j6ee1emXyl6Qkihh8UYSISi&ved=0ahUKEwiFmNSsvbb3AhUXQUEAHU_mDM4Q4dUDCAc&uact=5&oq=%D8%A3%D9%87%D9%85+%D8%A7%D9%84%D8%A3%D8%AE%D8%A8%D8%A7%D8%B1&gs_lcp=Cgdnd3Mtd2l6EAMyBwgjEOoCECcyBwgjEOoCECcyBwgjEOoCECcyBwgjEOoCECdQpQlYpQlgng5oAXAAeACAAQCIAQCSAQCYAQCgAQKgAQGwAQQ&sclient=gws-wiz">هم الأخبار </a>التي تم جمعها من مواقع على الانترنت من جميع أنحاء العالم العربي و العالم. إننا في نقدم لكم أ<a href="/">خبار موريتانيا</a>, أخبار مصر, <a href="/Arabs">أخبار فلسطين, </a> أخبار المغرب و أخبار الجزائر, أخبار تونس و أخبار ليبيا, أخبار لبنان و سوريا, أخبار السعودية, أخبار الإمارات, أخبار الكويت و أخبار  قطر, أخبار اليمن و أخبار العراق, أخبار البحرين و أخبار  عمان, أخبار الأردن و أخبار العالم أوّلًا بأوّل، وعلى مدار 24 ساعة.

    </p>
  
         </div>
 
     </div>
   </div>

     <div className='row'>
       <div className="col-md-7 col-lg-7">
        <MyGAd3 />
       </div>
     </div>
        
</div>
</div>

   )  ;
 
} export default Terms;
