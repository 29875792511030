import React from "react";
//import logo from './logo.svg';
//import axios from 'axios';
import './App.css';

import Myrout from './components/Myrout';
//import {Helmet} from "react-helmet";
import { Helmet, HelmetProvider } from 'react-helmet-async';

    

function App() {
  return (
    <HelmetProvider>
     <Helmet>
     </Helmet>
    <Myrout/>
     
    </HelmetProvider>

  );
}



export default App;
