import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { hydrate, render } from "react-dom";
import { hydrateRoot } from 'react-dom/client';
 

/*ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

/*const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  //hydrate(<App />, rootElement);
  hydrateRoot(rootElement, <App />);
} else {
  //render(<App />, rootElement);
  hydrateRoot(rootElement, <App />);
}*/

const container = document.getElementById('root');
if (container.hasChildNodes()) {
//const root = hydrateRoot(container, <App />);
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
} else {
 // const root = hydrateRoot(container, <App />);
 const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App />);
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
