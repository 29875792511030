 import React from "react";
//import ReactPlayer from 'react-player';
/*import MyGAd from '../components/MyGad';*/
//import CookieConsent from "react-cookie-consent";

function Footer(props) {
    return (
      <div>
      
      
  

      </div>

    )
 ;
} export default Footer;

