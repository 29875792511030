import React from "react";


  
 // let nav = document.getElementById('navbarExample');  

class NavBR extends React.Component {

     handleClickb(){
     /*let open = 1;

     let navi = document.getElementById('navbarExample');  
      if(open == 1)  {
      navi.classList.add("open");
      open = 0;
    }
    else if(open == 0){
      navi.classList.remove("open");
      open = 1;
    }
     alert(open);*/

     
// Navbar on mobile
let elements = document.querySelectorAll(".nav-link:not(.dropdown-toggle)");

for (let i = 0; i < elements.length; i++) {
  elements[i].addEventListener("click", () => {
    document.querySelector(".offcanvas-collapse").classList.toggle("open");
  });
}

document.querySelector(".navbar-toggler").addEventListener("click", () => {
    document.querySelector(".offcanvas-collapse").classList.toggle("open");
});

    }

  render() {
    return <nav id="navbarExample" className="navbar navbar-expand-lg fixed-top navbar-light extra-page" aria-label="Main navigation">
        <div className="container">
                       <a className="navbar-brand logo-image" href="/"><img src="logo.png" width="160" alt="logo" /></a> 
            <button className="navbar-toggler p-0 border-0" onClick={this.handleClickb} type="button" id="navbarSideCollapse" aria-label="Toggle navigation" >
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
                <ul className="navbar-nav ms-auto navbar-nav-scroll">
                    <li className="nav-item">
                        <a className="nav-link active" aria-current="page" href="/">موريتانيا</a>
                    </li>
                     <li className="nav-item">
                        <a className="nav-link" href="/middle-east">الشرق الأوسط</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/egypt">مصر</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="gulf-arabic">الخليج</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/maghreb"> المغرب العربي</a>
                    </li>
                </ul>
            </div> 

        </div> 
    </nav>
    ;
  }
} export default NavBR;
