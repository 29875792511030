
import React from "react";
import MyGAd3 from '../components/MyGad3';

function Publicity(props) {
    return (
<div className="col-xl-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xl-5">
      </div>
         <div className="col-lg-7 col-xl-7">
       
          
           <div className="textarab" dir="rtl">
           <h1>خدماتنا الإعلانية</h1>
        <br/>
          <ul>
          <li>بانر إعلاني</li>
          <li>الإعلانات المضمنة</li>
          <li>الإعلانات على صفحاتنا في فيسبوك و انستاجرام</li>
          
          </ul>

          </div>
           <b><a href="/contact" >للأإتصال  </a></b>

           <br/>
       </div>

    </div>

    <div className='row'>
      <div className="col-md-7 col-lg-7">
        <MyGAd3 />
      </div>
      
    </div>
        
</div>
</div>
   )  ;
 
} export default Publicity;
