import React from "react";
///import MyGad from './MyGad';

class Header extends React.Component {
  render() {
    return ( 
     <header id="header" className="header">
           <h1><a className="" href="/">افنان نيوز </a></h1>       
    </header>
    )

    ;
  }
} export default Header;