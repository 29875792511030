
import React from "react";
import MyGAd3 from '../components/MyGad3';

function Privacy(props) {
    return (
<div className="col-xl-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xl-5">
      </div>
         <div className="col-lg-7 col-xl-7">
       

        <h1>سياسة الخصوصية</h1>
        <br/>
        <div className="textarab" dir="rtl">
        <br/>
           <p>عندما تستخدم مواقعنا او تطبيقاتنا او خدمات شركائنا، فإن ذلك يتيح لنا ولهم جمع أنواع مختلفة من بياناتك، مثل المقالات التي قرأتها، بيانات&nbsp;المنتجات&nbsp;</p><p>التي اشتركت فيها، وبيانات جهازك وغيرها الكثير</p>.
<p>قد تستخدم&nbsp;هذه البيانات لأغراض مختلفة، منها تزويدك بمحتوى وإعلانات مخصصة لك، ولتوصيل منتجاتنا بناءً على طلبك </p><p>الحفاظ على أمن أنظمتنا</p>
<p>وتتضمّن المعلومات التي نجمعها&nbsp;الكوكيز، ونوع المتصفّح وإعداداته، ونوع الجهاز وإعداداته، ونظام التشغيل، وشبكة الجوّال </p><p>بما في ذلك اسم مشغل شبكة الجوّال ورقم الهاتف ورقم إصدار التطبيق </p><p> كما يتم جمع المعلومات حول تفاعل تطبيقاتك ومتصفّحاتك وأجهزتك مع خدماتنا، بما في ذلك&nbsp;عنوان<span> IP&nbsp;</span>وأمور أخرى</p>
<p>سنقوم بإجراء تغييرات على سياسة الخصوصية هذه من آن لآخر. سنبلغك مسبقًا بأي تغييرات جديدة</p>
<p>&nbsp;</p>
</div>
                     </div>

    </div>

    <div className='row'>
      <div className="col-md-7 col-lg-7">
        <MyGAd3 />
      </div>
      
    </div>
        
</div>
</div>

   )  ;
 
} export default Privacy;
