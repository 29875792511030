
import React from "react";
import MyGAd from '../components/MyGad';

function Terms(props) {
    return (
<div className="col-xl-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xl-5">
      </div>
         <div className="col-lg-7 col-xl-7">
       
          <h1> شروط الاستخدام  </h1>
          <div className="textarab" dir="rtl">
          <br/>
          <p>نرجو منك قراءة الأحكام والشروط بعناية، حيث أنها تشكل أساس الاتفاقية الموقعة بينك وبين الموقع.</p>
<p>&nbsp;</p>
<p>عند استخدامكم لموقعنا او لإحدى خدماته، فإنكم توافقون على الالتزام بالأحكام والشروط، التالية وكذلك سياسة الخصوصية<span>&nbsp;</span>لموقعنا :</p>
<ul>
<li>عدم استخدام خدمات الموقع لمحاولة الحصول على إمكانية الدخول إلى خوادم أو أنظمة الموقع.</li>
<li>عدم استخدام الخدمات الموقع في أي محتوى ضار بالأطفال أو مهدد أو مسيء لهم أو مؤذي أو تشهيري أو فاحش أو ينطوي على خصوصية الآخرين أو كريه أو عنصري أو عرقي أو غير ذلك من المحتوى البغيض.</li>
<li>عدم استخدام الخدمات الموقع انتهاك أي من القوانين أو اللوائح المعمول بها في بلدكم.</li>
<li>عدم استخدام الخدمات الموقع في نشر الفيروسات أو غيرها من الملفات او البرامج أو المحتوى المصمم لإيذاء أي من مستخدمين الانترنت.</li>
<li>عدم استخدام الخدمات الموقع</li>
<li>عدم استخدام الخدمات الموقع</li>
</ul>
<p></p>


          </div>
             </div>

    </div>

    <div className='row'>
      <div className="col-md-7 col-lg-7">
        <MyGAd />
      </div>
      
    </div>
        
</div>
</div>

   )  ;
 
} export default Terms;
