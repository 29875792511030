import React from "react";
//import ReactDOM from 'react-dom';


class Mymetas extends React.Component {

   constructor(props) {
    super(props);
    this.state = {
      description: this.props.description,
      url  :  this.props.url,
      image:  this.props.image,
      title:  this.props.title,
    };
    console.log('hemlet ## '+this.props.title);
     if(this.props.image === 'NULL')
        this.setState({  image:  '/evnan_logo4x4.jpg'});
    
  }

  render() { 
      
     
   return( 
        <div></div>
      )
  
  }
} export default Mymetas;
