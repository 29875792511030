import React from "react";
import AdSense from 'react-adsense';

 class MyGAd extends React.Component {

   
    componentDidMount() {
     
    }

   render () {
    return(
        <div>
     {/*  <AdSense.Google
         client='ca-pub-9101204197222280'
           slot='1412087155' 
             style={{display: 'block'  }}
             format='autorelaxed'
             responsive='true'
             layout='Display'/>
*/}
        </div>)  
    }
 }

  export default MyGAd;