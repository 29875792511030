import React from "react";
import AdSense from 'react-adsense';

 class MyGAd extends React.Component {

   
    componentDidMount() {
     
    }

   render () {
    return(
    <div>
    {/*   <AdSense.Google
         client='ca-pub-9101204197222280'
           slot='5067800710' 
             style={{display: 'inline-block', width: 468, height: 90  }}
             format='rectangle'
             responsive='true'
             layout='Display'/>
*/}
        </div> )
    }
 }

  export default MyGAd;