
import React from "react";

function Location(props) {
    return (
<section className="intro-single">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-2">
           
 <button onClick="topFunction()" id="myBtn" className="primary">
        <img src="images/up-arrow.png" alt="alternative"/>
    </button>
        </div>
      </div>
    </div>
  </section>

   )  ;
 
} export default Location;
