import React from "react";
import Badge from 'react-bootstrap/Badge'

class Mycard extends React.Component {

   constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      texte: props.texte,
      image: props.image,
      site : props.site,
      lien : props.lien,
      errorimage: "/evnan_logo4x4.png",
    };
    
  }

  render() {
    return (
<div>
<div className="mycard mb-3 .h-100">
  <img src={this.state.image} onError={(e)=>{e.target.onerror = null; e.target.src=this.state.errorimage}} height='180' alt="" className="mycard-img-top" />
  <div className="mycard-body">
    <h7 className="mycard-title text-rtl"><a href={this.state.lien} target="_blank" rel="noreferrer">{this.state.title.substring(0,45)}..</a></h7>
    <p className="mycard-text text-rtl"></p>
    <Badge  bg="warning">
    <a href={this.state.site} target="_blank" rel="noreferrer">{this.state.site} </a>
     </Badge>
  </div>
</div>
</div>

      );
  }
} export default Mycard;
