
import React from "react";
import MyGAd3 from '../components/MyGad3';

function Contact(props) {
    return (
<div className="col-xl-12">
  <div className="container">
    <div className="row">
      <div className="col-lg-5 col-xl-5">
      </div>
         <div className="col-lg-7 col-xl-7">
       
       <div className="textarab" dir="rtl">
           <h1>للأإتصال</h1>
           <br/>
           <br/>
           <table>
           <tr><td><h5><i className="fa fa-envelope"></i>ahmedabdsw@gmail.com </h5></td></tr>
           <tr> <td><h5><i className="fab fa-whatsapp"></i> <i className="fa fa-phone"></i>    + 222 42072008 </h5></td></tr>
           
           </table>

        </div>
         </div>

    </div>

    <div className='row'>
      <div className="col-md-7 col-lg-7">
        <MyGAd3 />
      </div>
      
    </div>
        
</div>
</div>
   )  ;
 
} export default Contact;
